import React from "react"
import Layout from "./../components/layout"
import PageHeader from "../components/pageHeader"
import image32 from "../img/Casa1.jpg"
import ReservationComponent from "../components/reservationComponent"
import { Col, Container, Row } from "reactstrap"

const Reservation = () => <Layout>
  <PageHeader backgroundImage={image32} title="Reservation"/>
  <ReservationComponent/>
  <section className="section section_phone section_no-padding_top">
    <Container className="container">
      <Row className="justify-content-lg-center section_phone__row">
        <Col lg={8}>
          <p className="font-small text-center">
            or call us:
          </p>
          <p className="section_phone__number text-center">
            <a href="tel:+6494899973">+64 9 4899973</a>
          </p>
        </Col>
      </Row>
    </Container>
  </section>
</Layout>

export default Reservation
